import {catchError, delay, map, of, take, tap} from "rxjs";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

export interface GeoLocationResponse {
    "country_code2": string,
}

@Injectable({
    providedIn: 'root'
})
export class UserCountryDetectorService {
    URL = "https://api.ipgeolocation.io/ipgeo?apiKey=bdd097e1482e4eb98281e1c3a96a3910"

    private userCountry: string;

    constructor(private httpClient: HttpClient) {
        this.getUserGeolocationInfo().pipe(
            take(1),
            tap(res => this.userCountry = (res as any).country_code2)
        ).subscribe()
    }

    getUserGeolocationInfo() {
        if (this.userCountry) {
            return of({country_code2: this.userCountry})
        }

        return this.httpClient.get<any>(this.URL)
            .pipe(
                map(res => ({country_code2: res.country_code2})),
                catchError(() => of(null)));
    }

    getUserCountry = () => this.userCountry
}
